@import "styles/global.scss";

.footer {
    background-color: $primary-blue-1;
    width: 100%;
    box-shadow: 0px 0px 3px 3px rgba(81, 81, 81, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    color: $primary-white-1;



    .footer__top-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px;

        .footer__logo {
            height: 35px;
            margin-right: 80px;
        }

        .footer__text {
            display: flex;
            border-left: 1px solid rgba(116, 116, 116, 0.311);

            .footer__text-header {
                color: $primary-orange-1;
                font-weight: $font-semibold;
            }

            .footer__links-section {
                padding: 20px 50px;

                .footer__link {
                    font-size: $font-size-0;
                    display: block;
                    margin-bottom: 8px;
                    cursor: pointer;
                }
            }

            .footer__about {
                padding: 20px 50px;

                .footer__text-description {
                    font-size: $font-size-0;
                }
            }
        }
    }

    .footer__bottom-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0px 20px 20px 20px;

        .footer__icon {
            font-size: 13px;
            margin-right: 5px;
        }

        .footer__bottom-section-privacy-policy {
            color: $primary-orange-1;
            font-weight: $font-semibold;
            text-decoration: underline;
            cursor: pointer;
        }

        p {
            color: $primary-white-2;
            padding: 3px;
            margin: 0;
            font-size: $font-size-0;
        }
    }
}