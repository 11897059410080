/* 
.form, .users {
  margin: 0px auto;
  width: 50%;
}

.form {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.form > div {
  margin-top: 10px;
}
.form button {
  margin-top: 20px;
  width: 100px;
} */

@import "styles/global.scss";

$nav-bar-height: 70px;
$side-drawer-open-width: 210px;
$side-drawer-close-width: 73px;

.external-container--without-side-drawer {
    min-height: 100vh;
}

.external-container--with-side-drawer {
    margin-left: $side-drawer-close-width;
}

.external-container--with-side-drawer-child {
    transition: $base-transition;
    min-height: 100vh;
}

.component-container {
    margin-top: $nav-bar-height;
    min-height: inherit;
    background-color: $primary-purple-3;
}

.external-container--side-drawer-open {
    margin-left: $side-drawer-open-width - $side-drawer-close-width;
    transition: $base-transition;
}

.home-loading-page {
    background-color: $primary-purple-1;
    height: 100vh;
    width: 100vw;
}

.home-loading-page__loading-logo {
    height: 40px;
    position: fixed;
    top: 39%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.container-padding {
    padding: 50px
}