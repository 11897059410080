@import "styles/variables.scss";

// Useful classes
.underline {
    text-decoration: underline;
}

.inline {
    display: inline;
}

.text-align-right {
    text-align: right;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800,900&display=swap');

h1 {
    font-weight: $font-semibold;
    font-size: $font-size-8;
    margin: 10px 0;
}

h3 {
    font-weight: $font-semibold;
    font-size: $font-size-5;
    margin: 0;
}

h4 {
    font-weight: $font-semibold;
    font-size: $font-size-4;
    margin: 0;
}

p {
    font-weight: $font-light;
    font-size: $font-size-1;
}

button {
    font-family: "Montserrat", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    border: none;
    background: none;
}

button:focus {
    outline: none;
}

input:focus {
    outline: none;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    letter-spacing: 1px;
}