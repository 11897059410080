// Transitions
$base-transition: all 0.3s ease-in-out;

// Blue
$primary-blue-1: rgba(#a4c2f4, 1);

// Purple
$primary-purple-1: #2f344b;
$primary-purple-2: rgba(#63618c, 1);
$primary-purple-3: #F3F4F8;

// Orange
$primary-orange-1: #FD990D;

// White
$primary-white-1: white;
$primary-white-2: rgba(#f5f4f4, 1);
$primary-white-3: rgba(#eeeeee, 1);
$primary-white-4: rgba(#a3a3a3, 0.8);

// Black
$primary-black-1: rgba(#121212, 1);
$primary-black-2: rgba(#404040, 1);
$primary-black-3: rgba(#2F2F2F, 1);
$primary-black-4: rgba(#515050, 0.8);

$primary-green-1: rgba(#7FB46C, 1);

$primary-blue-1: rgba(#313644, 1);

// ======== Fonts ========
$font-thin: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

$font-size-0: 10px;
$font-size-1: 13px;
$font-size-2: 15px;
$font-size-3: 20px;
$font-size-4: 25px;
$font-size-5: 30px;
$font-size-6: 35px;
$font-size-7: 40px;
$font-size-8: 45px;
$font-size-9: 50px;